<template>
  <div>
    <div class="globle_border">
      <div class="search-box">
        <el-select v-model="filterStatus" placeholder="请选择" @change="onScreenData">
          <el-option v-for="item in filterOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <div class="input-box">
          <el-input v-model="categoryName" @change="onScreenData" placeholder="搜索分类名称"></el-input>
        </div>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" v-loading="dataLoding">
          <el-table-column prop="number" label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="categoryName" label="分类名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdUserName" label="申请供应商"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdTime" label="申请时间"></el-table-column>
          <el-table-column show-overflow-tooltip prop="auditStatus" label="审核状态">
            <template slot-scope="scope">
              <span :style="`color:${scope.row.auditStatus == '待审核' ? '#EB2500' : ''}`">{{ scope.row.auditStatus }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="auditDate" label="审核时间" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="jump(scope.row)" v-if="scope.row.auditStatus == '审核不通过'">查看</el-button>
              <template v-if="scope.row.auditStatus == '待审核'">
                <el-button class="btn" type="primary" plain @click="handConfirm(scope.row, true)">通过</el-button>
                <el-button class="btn" type="danger" plain @click="handleDialog(scope.row, false)">不通过</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <!-- 编辑 -->
    <el-dialog title="审核不通过" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="160px" :show-message="false">
        <el-row>
          <el-col :span="20">
            <el-form-item label="审核不通过原因" prop="auditMark" required>
              <el-input v-model="ruleForm.auditMark" placeholder="请输入" type="textarea" maxlength="20" show-word-limit :autosize="{ minRows: 6, maxRows: 6 }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "typeReview",
  data() {
    return {
      dataLoding: false,
      filterStatus: 4,
      filterOptions: [
        { label: "全部状态", value: 4 },
        { label: "待审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "审核不通过", value: 2 },
      ],
      categoryName: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 弹窗
      dialogVisible: false,
      submitLoding: false,
      ruleForm: {},
    }
  },
  created() {
    this.getCategoryInfoAudit()
  },
  methods: {
    getCategoryInfoAudit() {
      this.dataLoding = true
      this.$axios
        .get(this.$api.categoryInfoAudit, {
          params: {
            categoryName: this.categoryName || null,
            page: this.currentPage,
            pageSize: this.pageSize,
            auditStatus: this.filterStatus == 4 ? null : this.filterStatus,
          },
        })
        .then(res => {
          this.dataLoding = false
          const result = res.data.result
          this.tableData = result.list.map(t =>
            Object.assign(t, {
              auditStatus: this.filterOptions.find(f => f.value == t.auditStatus)?.label,
            })
          )
          this.totalItemsCount = result.totalCount
        })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onScreenData()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getCategoryInfoAudit()
    },
    // 筛选
    onScreenData() {
      this.currentPage = 1
      this.tableData = []
      this.getCategoryInfoAudit()
    },
    // 查看
    jump(row) {
      const { categoryId } = row
      this.$router.push({ name: "typeDetail", query: { categoryId } })
    },
    // 弹窗
    handleDialog(item) {
      Object.assign(this.ruleForm, {
        categoryId: item.categoryId,
      })
      this.dialogVisible = true
    },
    onCancel() {
      this.$refs["ruleForm"].resetFields()
      this.dialogVisible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return this.$message.error("请输入原因")
        }
        this.handleAuditing(this.ruleForm, false)
      })
    },
    handConfirm(item, flag) {
      this.$confirm("确定该分类通过？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.handleAuditing(item, flag)
      })
    },
    // 审核
    handleAuditing(item, flag) {
      this.submitLoding = true
      this.$axios
        .post(this.$api.categoryInfoPutAudit, {
          categoryId: item.categoryId,
          auditStatus: flag ? 1 : 2,
          auditMark: item.auditMark,
        })
        .then(res => {
          this.submitLoding = false
          if (res.data.code == 100) {
            this.$message.success("审核成功")
            this.getCategoryInfoAudit()
            if (!flag) this.onCancel()
          } else {
            this.$message.error("审核失败")
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  .input-box {
    margin-left: 20px;
  }
}
</style>
